import React, { useEffect } from "react";
import "./CourseDetail.scss";
import { Button } from "@mui/material";
import RegisterForm from "./components/RegisterForm";
import SectionWrapper from "../../components/SectionWrapper/SectionWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import OfferBanner from "../../components/OfferBanner";

function CourseDetail() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const location = useLocation();
  const { course } = location.state || {};

  const navigate = useNavigate();

  useEffect(() => {
    if(!course){
      navigate("courses");
    }
    window.scrollTo({ top: 0});
  })

  return (
    <SectionWrapper>
      <div className="course-detail">
      <h1 className="course-title title">{course.title}</h1>
        <div className="course-detail__top">
          {/* Left Section */}
          <div className="course-detail__left">

            <div></div>
          
            <p className="description">{course.description}</p>
            <p className="description">
              <span>Prerequisites:</span> None, this course is suitable for
              beginners.
            </p>
            <p className="description">
              <span>Outcome:</span> By the end of this course, you'll be able to
              write Java programs, understand object-oriented concepts, and
              apply them to real-world problems.
            </p>
            <div className="course-info description">
              <p>Duration: 6 Weeks</p>
              <p>
                Mode:{" "}
                <span style={{ color: "rgba(118, 185, 0, 1)" }}>
                  Live Sessions
                </span>{" "}
                + Recorded Content
              </p>
            </div>
            <button className="demo-btn description" onClick={() => setOpen(true)}>
              Request a Demo
            </button>
          </div>

          {/* Right Section - Image */}
          <div className="course-detail__right">
            <img src={course.icon} alt="Course" />
          </div>
        </div>
        {/* Horizontal line */}
        <hr />

        <h1 style={{ textAlign: "left" }}>Course Content</h1>
        <div className="course-detail__list">
          <ul>
            {course.courseSyllabus.map((syllabus, index) => {
              return <li key={index}>{syllabus}</li>;
            })}
          </ul>
        </div>
      </div>

      <OfferBanner
        offerTitle="Special Offer"
        descLine1="Sign up for multiple courses and get a discount!"
        descLine2="Contact us to learn more about our special bundles and offers."
      />

      <RegisterForm isOpen={open} close={handleClose} />
    </SectionWrapper>
  );
}

export default CourseDetail;
