import "./PopularCourse.scss";
import { useState, useEffect } from "react";

import { courseList } from "../../../constants/listconstant";
import { useNavigate } from "react-router-dom";
import {
  fetchCourseById,
  flattenCourses,
} from "../../../utils/functionalUtils";
import { Fade, Slide } from "react-awesome-reveal";

function PopularCourse() {
  // const [courses, setCourses] = useState([]);
  // const [secondRowcourses, setCourses] = useState([]);

  var allCourses = flattenCourses(courseList);

  const navigate = useNavigate();
  // const updateCourses = () => {
  //   const screenWidth = window.innerWidth;

  //   if (screenWidth >= 1330) {
  //     // Show 6 items on desktop
  //     setCourses(allCourses);
  //   } else if (screenWidth >= 885 && screenWidth <= 1105) {
  //     // Show 2 items between 885px and 1075px
  //     setCourses(allCourses.slice(0, 2));
  //   } else if (screenWidth >= 650 && screenWidth < 1330) {
  //     // Show 4 items on tablet
  //     setCourses(allCourses.slice(0, 4));
  //   } else {
  //     // Show 4 items on mobile
  //     setCourses(allCourses.slice(0, 4));
  //   }
  // };

  const handleCardClick = (course) => {
    const courseState = fetchCourseById(course.id);
    navigate("/course-detail", {
      state: {
        course: courseState,
      },
    });
  };

  useEffect(() => {
    // setCourses(allCourses);
    // console.log(allCourses);
    // updateCourses();
    // window.addEventListener("resize", updateCourses);
    // return () => window.removeEventListener("resize", updateCourses);
  }, []);

  return (
    <div className="popular-course-section">
      <div className="course-content">
        <h2 className="course-content__title title">Popular Topics</h2>
        <h3 className="course-content__description description">
          Discover our most sought-after courses
          <br />
          that are helping students excel in their
          <br />
          academic and professional journeys. <br />
          These top courses are trending among
          <br />
          students for their comprehensive
          <br />
          content, expert instruction, and practical
          <br />
          applications.
        </h3>
        <a href="/courses" className="course-content__view-all-btn description">
          View All
        </a>
      </div>

      <div className="populdar-course-container">
        <div className="populdar-course-container__cards">
          {allCourses.slice(0, 3).map((course, index) => (
            <div
              key={index}
              className="popular-course-card"
              onClick={() => handleCardClick(course)}
            >
              <img
                src={course.image}
                className="popular-course-card__image"
                alt="Course"
              />
              <h4 className="popular-course-card__title">{course.name}</h4>
            </div>
          ))}
        </div>
        <div className="populdar-course-container__cards">
          {allCourses.slice(3, 6).map((course, index) => (
            <div
              key={index}
              className="popular-course-card"
              onClick={() => handleCardClick(course)}
            >
              <img
                src={course.image}
                className="popular-course-card__image"
                alt="Course"
              />
              <h4 className="popular-course-card__title">{course.name}</h4>
            </div>
          ))}
        </div>

        <a href="/courses" className="course-content__responsive-view-all-btn">
          View All
        </a>
      </div>

      {/* <div className="popular-courses">
        {courses.map((course, index) => (
          <div
            key={index}
            className="popular-course-card"
            onClick={() => handleCardClick(course)}
          >
            <img
              src={course.image}
              className="popular-course-card__image"
              alt="Course"
            />
            <h4 className="popular-course-card__title">{course.name}</h4>
          </div>
        ))}

        
      </div> */}
    </div>
  );
}

export default PopularCourse;
