// src/components/PrivacyPolicy.js
import React from "react";
import "./PrivacyPolicy.scss";

import ContactForm from "../ContactUs/components/ContactForm/index";

const PolicySection = ({ title, subtitle, content }) => {
  return (
    <section className="policy-section">
      <h2 className="section-title">{title}</h2>
      {subtitle && <h3 className="section-subtitle">{subtitle}</h3>}
      <div className="section-content">{content}</div>
    </section>
  );
};

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">Privacy Policy </h1>

      <PolicySection
        title="Welcome to Zenith"
        content="At Zenith, we take your privacy and data security seriously. This Privacy Policy outlines how we collect, use, share, and protect your information when you access and use our Zenith. By using our platform, you agree to the terms of this Privacy Policy."
      />

      <PolicySection
        title="1. Information We Collect"
        content={
          <>
            We collect the following types of information to provide our
            services and improve user experiences:
            <br />
            <strong>Personal Information</strong>
            <br />
            <strong>Account Information:</strong> When users sign up, we collect
            personal information such as name, email address, phone number, and
            password.
            <br />
            <strong>Profile Data:</strong> If users create profiles, we collect
            additional details like profile pictures, bio, interests, and social
            links.
            <br />
            <strong>Payment Information</strong>
            <br />
            We collect payment details such as credit card information or other
            financial data to process transactions. These details are securely
            processed by third-party payment providers and are not stored on our
            servers.
            <br />
            <strong>Course and Learning Data</strong>
            <br />
            <strong>Course Data:</strong> When users enroll in or create
            courses, we collect and store course materials, progress,
            assessments, and interaction data.
            <br />
            <strong>Learning Activity:</strong> We collect data on course
            completion, test scores, discussions, and feedback.
            <br />
            <strong>Technical Information</strong>
            <br />
            <strong>Device Information:</strong> Information about your device,
            browser, and IP address when you access the platform.
            <br />
            <strong>Usage Data:</strong> We collect data about how users
            navigate the platform, including clicks, page views, and time spent
            on different pages.
            <br />
            <strong>Cookies and Tracking</strong>
            <br />
            We use cookies and similar technologies to enhance user experience,
            remember login details, and monitor site performance.
          </>
        }
      />

      <PolicySection
        title="2. How We Use Your Information"
        content={
          <>
            The information we collect is used to:
            <br />
            <strong>Provide Services:</strong> To create and maintain user
            accounts, facilitate course delivery, and enable interactions
            between students and instructors.
            <br />
            <strong>Improve Platform Functionality:</strong> Analyze user
            behavior to enhance the functionality and user experience of the
            platform.
            <br />
            <strong>Send Notifications:</strong> Send important updates,
            course-related information, or marketing communications (with the
            option to opt-out).
            <br />
            <strong>Process Payments:</strong> To securely process payment
            transactions and manage subscriptions.
            <br />
            <strong>Comply with Legal Obligations:</strong> As required by
            applicable laws, regulations, or legal processes.
          </>
        }
      />

      <PolicySection
        title="3. Data Security"
        content={
          <>
            We are committed to protecting your data. The following measures are
            in place to safeguard your information:
            <br />
            <strong>Encryption:</strong> All sensitive data, such as personal
            and financial information, is encrypted during transmission using
            SSL (Secure Socket Layer) technology.
            <br />
            <strong>Access Control:</strong> Only authorized personnel have
            access to personal data, and they are bound by strict
            confidentiality agreements.
            <br />
            <strong>Password Protection:</strong> Passwords are stored in an
            encrypted format, and we encourage users to select strong passwords
            and update them regularly.
            <br />
            <strong>Third-Party Security:</strong> We only work with third-party
            services that comply with industry standards for data protection.
            <br />
            <strong>Regular Audits:</strong> We conduct regular security audits
            and updates to maintain the integrity of our systems.
          </>
        }
      />

      <PolicySection
        title="4. User Account and Data Access"
        content={
          <>
            Users can:
            <br />
            <strong>Access and Update Information:</strong> Users can access and
            update their personal information by logging into their accounts.
            <br />
            <strong>Delete Account:</strong> Users may request the deletion of
            their accounts by contacting us at [email address], though we may
            retain certain information for legal and operational purposes.
            <br />
            <strong>Data Portability:</strong> Users have the right to request a
            copy of their data in a portable format.
            <br />
            <strong>Restrict or Object to Processing:</strong> Users may request
            limitations on how their data is used or object to specific
            processing activities.
          </>
        }
      />

      <PolicySection
        title="5. Third-Party Services"
        content="We use third-party services for payment processing, data storage, analytics, and customer support. These services comply with relevant data protection laws and have their own privacy policies. We do not sell or share personal information with third parties for marketing purposes."
      />

      <PolicySection
        title="6. Cookies and Tracking Technologies"
        content={
          <>
            We use cookies and similar tracking technologies to:
            <br />
            <strong>Remember User Preferences:</strong> Save login information
            and user settings.
            <br />
            <strong>Analyze Site Traffic:</strong> Monitor traffic and user
            behavior to improve the platform's performance and usability.
            <br />
            <strong>Targeted Advertising:</strong> Provide personalized content
            and advertising where applicable.
            <br />
            Users can manage cookie preferences via browser settings or opt out
            of targeted advertising by using tools such as [opt-out tools].
          </>
        }
      />

      <PolicySection
        title="7. Children's Privacy"
        content="Our platform is not intended for children under the age of 13. We do not knowingly collect personal information from children without parental consent. If you believe that we have inadvertently collected information from a child, please contact us to delete such data."
      />

      <PolicySection
        title="8. Data Retention"
        content="We retain user data for as long as necessary to provide our services and fulfill our legal obligations. Inactive accounts may be deleted after a certain period, and users will be notified in advance."
      />

      <PolicySection
        title="9. International Data Transfers"
        content="If you access our services from outside your country of residence, please be aware that your information may be transferred to, stored, and processed in countries with data protection laws different from those in your country. We take steps to ensure that data transferred internationally is protected in accordance with applicable laws."
      />

      <PolicySection
        title="10. Changes to the Privacy Policy"
        content="We may update this Privacy Policy from time to time. Changes will be posted on this page, and users will be notified through email or platform alerts if changes are significant."
      />

      <PolicySection
        title="11. Your Rights and Choices"
        content={
          <>
            Depending on your jurisdiction, you may have the following rights:
            <br />
            <strong>Access:</strong> Request access to the personal information
            we hold about you.
            <br />
            <strong>Correction:</strong> Request that we correct inaccurate or
            incomplete information.
            <br />
            <strong>Erasure:</strong> Request the deletion of your personal
            information under certain circumstances.
            <br />
            <strong>Objection:</strong> Object to processing or restrict certain
            types of data processing.
            <br />
            <strong>Opt-Out of Marketing:</strong> Opt-out of receiving
            marketing communications by following the unsubscribe instructions
            in the email or contacting us directly.
            <br />
            To exercise any of these rights, please contact us at [email
            address].
          </>
        }
      />

      <PolicySection
        title="12. Contact Us"
        content={
          <>
            If you have any questions or concerns about this Privacy Policy or
            how we handle your personal data, please contact us at:
            <p className="section-content">
              If you have any questions or concerns about these Terms and
              Conditions, please contact us at:
              <br />
              Zenith LMS and Teaching Platform
              <br />
              Email: support@zenith.com
              <br />
              Phone: +91 9012894038
              <p className="footer__description" style={{ color: "#000" }}>
                Address: Plot No. 83, Block No. 6, <br className="break-line" />
                APIIC Colony, Opposite to Radhika Theater,{" "}
                <br className="break-line" />
                ECIL Post, <br className="break-line" />
                Hyderabad, 500062
              </p>
            </p>
          </>
        }
      />
    </div>
  );
};

export default PrivacyPolicy;
