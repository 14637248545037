import './HeaderContent.scss';

function HeaderContent({ title,description,image ,isImageFirst }) {
    return (
        <div className={`header-content ${isImageFirst ? 'header-content--reverse' : 'header-content--normal'}`} >
            <div className="header-content__left">
                <h1 className="header-content__title  title" style={{margin:"0px"}}>{title}</h1>
                <p className="header-content__description  description">
                    {description}
                </p>
            </div>
            <div className="header-content__right">
                <img src={image} alt="Bookmark" className="header-content__image" />
            </div>
        </div>
    );
}

export default HeaderContent;
