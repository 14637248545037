import SectionWrapper from "../SectionWrapper/SectionWrapper";
import "./SearchField.scss";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

function SearchField({
  autoSearchData,
  handleOnSearch,
  handleOnSelect,
  handleOnFocus,
  searchKeys,
}) {
  return (
    <SectionWrapper>
      <div className="search-container">
        <ReactSearchAutocomplete
          className="search-input"
          items={autoSearchData}
          onSearch={handleOnSearch}
          onSelect={handleOnSelect}
          onFocus={handleOnFocus}
          fuseOptions={{ keys: searchKeys }} // Allow searching by name and description
          resultStringKeyName="name"
          type="text"
          placeholder="Search the course you want to learn"
        />
      </div>
    </SectionWrapper>
  );
}

export default SearchField;
