

import './WhyZenithHeroSection.scss'

import WhyZenithMisson from '../WhyZenithMisson/index';

import {whyZenithMain} from '../../../assets/images/whyZenith/index'

function  WhyZenithHeroSection(){

    return(<div className='whyZenithHeroSection'>

    <div  className='whyZenithHeroSection__left'>
    <WhyZenithMisson  title="Our Mission"  description="At Zenith, we are committed to providing smart learning experiences that empower Gen Z engineering students to acquire the skills they need to be future-ready. We understand that every learner is unique, and we take a personalized approach to evaluate each learner's needs and guide them toward success. By tailoring the learning journey, we ensure that students are equipped to face the challenges of tomorrow with confidence." />
    <WhyZenithMisson  title="Our Vision"  description="At Zenith, innovation never stops. We continuously strive to offer students the best-in-class learning experience by leveraging cutting-edge artificial intelligence and analytical tools. Our goal is to help students achieve their ambitions through a learning environment that is both dynamic and future-focused."/>
   
    </div>

    <div  className='whyZenithHeroSection__right'>

        <img  src={whyZenithMain}></img>

        <h1 className='whyZenithHeroSection__right__slogan'> Connect , Level Up , Thrive</h1>
    </div>
    
    </div>);
}


export default WhyZenithHeroSection