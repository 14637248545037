import React from "react";
import "./NotFound.scss";
import { Zenith404 } from "../../assets/images/NotFound";
import SectionWrapper from "../../components/SectionWrapper/SectionWrapper";

const NotFound = () => {
  return (
    <SectionWrapper>
      <div className="not-found">
        <img src={Zenith404} alt="404" className="not-found__illustration" />
        <p className="not-found__message">
          Sorry, the page you're looking for cannot be found.
        </p>
        <a href="/" className="not-found__link">
          Return Home
        </a>
      </div>
    </SectionWrapper>
  );
};

export default NotFound;
