import React from "react";
import "./ComingSoon.scss";

import { CloudsIllustration } from "../../assets/images/ComingSoon";
import SectionWrapper from "../../components/SectionWrapper/SectionWrapper"

const ComingSoon = () => {
  return (
    <SectionWrapper>
      <div className="coming-soon">
        {/* <h1 className="coming-soon__title">Coming Soon</h1> */}
        <div className="coming-soon__illustration">
          <img
            src={CloudsIllustration}
            alt="coming soon icon"
            style={{ width: "10vw" }}
          />
          <img
            src={CloudsIllustration}
            alt="coming soon icon"
            style={{ width: "10vw" }}
          />
        </div>
        <p className="coming-soon__title">Under Construction</p>
        <p className="coming-soon__decorative-title">COMING SOON</p>
        <p className="coming-soon__message">
          Sorry, the page you're looking for is under constructions !!.
        </p>
        <a href="/" className="coming-soon__link">
          Return Home
        </a>
      </div>
    </SectionWrapper>
  );
};

export default ComingSoon;
