import React from "react";
import "./TermsAndConditions.scss";
import ContactForm from "../ContactUs/components/ContactForm";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions-container">
      <h1 className="terms-and-conditions-title">
        Terms and Conditions for Zenith{" "}
      </h1>

      <section className="terms-section">
        <h2 className="section-title">1. Acceptance of Terms</h2>
        <p className="section-content">
          By using Zenith, you acknowledge that you have read, understood, and
          agree to be bound by these Terms and Conditions, as well as our
          Privacy Policy. If you do not agree to these terms, please do not use
          our platform.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="section-title">2. Use of the Platform</h2>
        <p className="section-content">
          <strong>Eligibility:</strong> You must be at least 13 years old to use
          our platform. By using Zenith, you represent that you meet this age
          requirement.
          <br />
          <strong>Account Creation:</strong> To access certain features, you
          must create an account. You agree to provide accurate and complete
          information and to keep your account credentials confidential. You are
          responsible for all activities that occur under your account.
          <br />
          <strong>Prohibited Activities:</strong> You agree not to engage in any
          of the following prohibited activities:
          <ul>
            <li>
              Using the platform for unlawful purposes or in violation of any
              applicable laws or regulations.
            </li>
            <li>
              Posting, transmitting, or sharing any content that is defamatory,
              obscene, abusive, or otherwise objectionable.
            </li>
            <li>
              Attempting to gain unauthorized access to any part of the platform
              or other users' accounts.
            </li>
            <li>Interfering with the operation or security of the platform.</li>
          </ul>
        </p>
      </section>

      <section className="terms-section">
        <h2 className="section-title">3. Intellectual Property</h2>
        <p className="section-content">
          <strong>Ownership:</strong> All content, trademarks, and other
          intellectual property on Zenith are owned by or licensed to us. You
          may not use, reproduce, or distribute any content from the platform
          without our prior written consent.
          <br />
          <strong>User Content:</strong> By submitting content to Zenith, you
          grant us a worldwide, non-exclusive, royalty-free license to use,
          reproduce, modify, and display such content in connection with the
          platform.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="section-title">4. Subscription and Payments</h2>
        <p className="section-content">
          <strong>Fees:</strong> Some features of Zenith may require payment.
          You agree to pay all applicable fees for subscriptions or services as
          described on the platform.
          <br />
          <strong>Payment Processing:</strong> Payments are processed through
          third-party payment providers. We do not store your payment
          information, and we are not responsible for any issues that arise
          during payment processing.
          <br />
          <strong>Refunds:</strong> Refunds, if applicable, are governed by our
          refund policy, which is available on the platform.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="section-title">5. Termination</h2>
        <p className="section-content">
          <strong>Termination by You:</strong> You may terminate your account at
          any time by following the account deletion instructions on the
          platform.
          <br />
          <strong>Termination by Us:</strong> We reserve the right to suspend or
          terminate your account if you violate these Terms and Conditions or if
          we believe that your use of the platform poses a risk to its operation
          or security.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="section-title">
          6. Disclaimers and Limitation of Liability
        </h2>
        <p className="section-content">
          <strong>Disclaimer:</strong> Zenith is provided "as is" without any
          warranties, express or implied. We do not guarantee the accuracy,
          reliability, or availability of the platform.
          <br />
          <strong>Limitation of Liability:</strong> To the fullest extent
          permitted by law, we are not liable for any indirect, incidental,
          special, or consequential damages arising out of your use of the
          platform.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="section-title">7. Indemnification</h2>
        <p className="section-content">
          You agree to indemnify and hold harmless Zenith, its affiliates,
          officers, directors, employees, and agents from any claims,
          liabilities, damages, losses, and expenses arising out of or related
          to your use of the platform or any violation of these Terms and
          Conditions.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="section-title">8. Changes to Terms</h2>
        <p className="section-content">
          We may update these Terms and Conditions from time to time. Any
          changes will be posted on this page, and your continued use of the
          platform constitutes acceptance of the updated terms.
        </p>
      </section>

      <section className="terms-section">
        <h2 className="section-title">9. Governing Law</h2>
        <p className="section-content">
          These Terms and Conditions are governed by and construed in accordance
          with the laws of [Your Jurisdiction]. Any disputes arising under these
          terms will be subject to the exclusive jurisdiction of the courts in
          [Your Jurisdiction].
        </p>
      </section>

      <section className="terms-section">
        <h2 className="section-title">10. Contact Us</h2>
        <p className="section-content">
          If you have any questions or concerns about these Terms and
          Conditions, please contact us at:
          <br />
          Zenith LMS and Teaching Platform
          <br />
          Email: support@zenith.com
          <br />
          Phone: +91 9012894038
          <p className="footer__description" style={{ color: "#000" }}>
            Address: Plot No. 83, Block No. 6, <br className="break-line" />
            APIIC Colony, Opposite to Radhika Theater,{" "}
            <br className="break-line" />
            ECIL Post, <br className="break-line" />
            Hyderabad, 500062
          </p>
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
