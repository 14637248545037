import React from "react";
import "./LearningSession.scss";
import { Slide } from "react-awesome-reveal";

function LearningSession({ slogan, title, description, isReverse, image }) {
  return (
    <div
      className={`learning-session ${
        isReverse ? "alignComponentRight" : "alignComponentleft"
      }`}
    >
      <Slide
        duration={1500}
        direction={isReverse ? "right" : "left"}
        triggerOnce
      >
        <img
          src={image}
          alt="Learning Session"
          className={`learning-session__image`}
        />
      </Slide>

      <div
        className={`learning-session__content ${
          isReverse ? "reverse" : "normal"
        }`}
      >
        <h3 className="learning-session__tagline">{slogan}</h3>
        <h1 className="learning-session__title">{title}</h1>
        <p className="description">{description}</p>
      </div>
    </div>
  );
}

export default LearningSession;
