import './WhyZenith.scss'

import WhyZenithMisson from './WhyZenithMisson/index'
import WhyZenithHeroSection  from './WhyZenithHeroSection/index.jsx'

import WhyZenithReasons from './WhyZenithReasons/index.jsx'

function WhyZenith(){



    return (<>
   <WhyZenithHeroSection/> 
   <WhyZenithReasons/>
   </>
    )
}


export default WhyZenith