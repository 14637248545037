import React from "react";
import HomeMainsection from "./HomeMainsection";
import LearningSession from "./LearningSession";

import PopularCourse from "./PopularCourse";
import ContactForm from "../ContactUs/components/ContactForm";
import {
  liveSession,
  flexibleSession,
  recording,
} from "../../assets/images/HomePage/index";
import Carousel from "./Testimonials/components/carousel/Carousel";
import TestimonialsComponent from "../../components/TestimonialsComponent";
import OfferBanner from "../../components/OfferBanner";
import SectionWrapper from "../../components/SectionWrapper/SectionWrapper";

function HomePage() {
  return (
    <>
      <HomeMainsection />

      <LearningSession
        image={liveSession}
        title="Live sessions with expert tutors via Zoom."
        description="Experience live, interactive sessions with seasoned tutors via Zoom. Ask questions in real-time, collaborate with peers, and get instant feedback to enhance your learning experience."
        slogan="Interactive Learning"
      />

      <LearningSession
        image={flexibleSession}
        isReverse={true}
        title="Enroll in courses that fit your schedule and learn at your own pace."
        description="Our LMS is designed for the modern student. Enroll in courses that fit your schedule, access materials 24/7, and learn at your own pace, from anywhere in the world."
        slogan="Flexible Access"
      />

      <LearningSession
        image={recording}
        title="Missed a class? No worries. Access all recording sessions anytime."
        description="All our sessions are recorded and available on-demand so you can review key concepts or catch up on any missed content anytime, anywhere."
        slogan="Recorded Sessions"
      />
      <PopularCourse />

      <TestimonialsComponent />
      <SectionWrapper>
        <OfferBanner
          offerTitle="Kickstart Your career with 
        Real-World Experience"
          descLine1="Join our exclusive internships designed to boost your skills and help you shine!"
          descLine2="Get hands-on experience, work on live projects, and stand out in the competitive tech world. Ready to level up?"
        />
      </SectionWrapper>

      <ContactForm />
    </>
  );
}

export default HomePage;
