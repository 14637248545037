import React from "react";
import "./Footer.scss";
import { logo_light,zLearnMainLogo } from "../../assets/logo/index";
import { FaInstagram, FaFacebook, FaTwitter ,FaYoutube } from "react-icons/fa";
import { privacyPage, termsAndConditions } from "../../constants/routes";

import { youtubeUrl,instagramUrl,twitterUrl} from '../../constants/url_constants'

import { aboutUsPage ,whyZenithPage,contactUsPage } from "../../constants/routes";
function Footer() {
  const companyLinks = [
    { title: "Why Zenith", url: whyZenithPage },
    { title: "About Us", url: aboutUsPage },
    { title: "Contact Us", url: contactUsPage },
   
  ];

  const socialLinks = [
    { title: "Youtube", url: youtubeUrl, icon: <FaYoutube /> },
    { title: "Instagram", url: instagramUrl, icon: <FaInstagram /> },
    { title: "Twitter", url: twitterUrl, icon: <FaTwitter /> },
  ];

  return (
    <div className="footer">
             <div className="footer-logo-container"><img src={zLearnMainLogo} alt="footer logo" className="footer__logo" /></div>
      <div className="footer__container">
        <div className="footer__left">
     
          <div className="footer__details">
            <p className="footer__title">Location</p>
            <p className="footer__description">
              Plot No. 83, Block No. 6, <br className="break-line" />
              APIIC Colony, Opposite to Radhika Theater, <br className="break-line" />
              ECIL Post, <br  className="break-line"/>
              Hyderabad, 500062
            </p>
          </div>
          <div className="footer__details-contact">
            <div className="footer__details">
              <p className="footer__title footer__title--contact">Contact</p>
              <a href="tel:+919000055264"  className="footer__description">+91 9000055264</a>
            </div>
            <div className="footer__details">
              <p className="footer__title footer__title--contact">Email</p>
              <a href="mailto:support@zenith.in" className="footer__description">support@zenith.in</a>
            </div>
          </div>
        </div>
        <div className="footer__right">
          <QuickLinks title="Our Company" links={companyLinks} />
          <QuickLinks title="Follow Us" links={socialLinks} />
          
        </div>
      </div>
      <div className="footer__terms">
        <div className="footer__terms-left">
          <p className="footer__terms-description">© 2024 Zenith. All Rights Reserved.</p>
        </div>
        <div className="footer__terms-right">
          <a href={privacyPage} className="footer__terms-link">
            Privacy Policy
          </a>
          <a href={termsAndConditions} className="footer__terms-link">
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  );
}

function QuickLinks({ title, links }) {
  return (
    <div className="footer__links">
      <p className="footer__title">{title}</p>
      {links.map((link, index) => (
        <a key={index} href={link.url} className="footer__description">
          {link.icon && <span className="footer__icon">{link.icon}</span>}
          {link.title}
        </a>
      ))}
    </div>
  );
}

export default Footer;
