import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore/lite";

const firebaseConfig = {
  apiKey: "AIzaSyD-AccSccJoTwFSegqCxBYbthY_lh98pKY",
  authDomain: "zenith-site.firebaseapp.com",
  projectId: "zenith-site",
  storageBucket: "zenith-site.appspot.com",
  messagingSenderId: "945779529999",
  appId: "1:945779529999:web:74791db4d0f4130ba7867a",
  measurementId: "G-KXHNYSLJM1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
