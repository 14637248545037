import './SectionWrapper.scss'; // SCSS file for custom styling

// SectionWrapper Component
const SectionWrapper = ({ children }) => {
  return (
    <div className="section-wrapper">
      {children}
    </div>
  );
};

export default SectionWrapper;