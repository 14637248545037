import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "../pages/Home/HomePage.jsx";
import CoursesPage from "../pages/CoursePage/index.jsx";
import ContactUs from "../pages/ContactUs";
import NotFound from "../pages/NoFoundPage/index.jsx";
import HeaderWrapper from "../pages/HeaderWrapper/index.js";
import AboutUs from "../pages/AboutUs/AboutUs.jsx";
import PrivacyPolicy from "../pages/PrivacyPage/index.jsx";
import TermsAndConditions from "../pages/TermsAndConditions/index.jsx";

import WhyZenith from "../pages/WhyZenith/index.jsx";

import * as routes from "../constants/routes.js";
import CourseDetail from "../pages/CourseDetail/index.js";
import ComingSoon from "../pages/ComingSoonPage/index.jsx";

const router = createBrowserRouter([
  {
    path: routes.homePage,
    element: <HeaderWrapper child={<HomePage />} />,
  },
  {
    path: routes.whyZenithPage,
    element: <HeaderWrapper child={<WhyZenith />} />,
  },
  {
    path: routes.coursePage,
    element: <HeaderWrapper child={<CoursesPage />} />,
  },
  {
    path: routes.courseDetail,
    element: <HeaderWrapper child={<CourseDetail />} />,
  },
  {
    path: routes.contactUsPage,
    element: <HeaderWrapper child={<ContactUs />} />,
  },
  {
    path: routes.aboutUsPage,
    element: <HeaderWrapper child={<AboutUs />} />,
  },
  {
    path: routes.privacyPage,
    element: <HeaderWrapper child={<PrivacyPolicy />} />,
  },
  {
    path: routes.termsAndConditions,
    element: <HeaderWrapper child={<TermsAndConditions />} />,
  },
  {
    path: routes.comingSoon,
    element: <HeaderWrapper child={<ComingSoon />} />,
  },
  {
    path: "*",
    element: <HeaderWrapper child={<NotFound />} />,
  },
]);

const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;
