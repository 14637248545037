import { useNavigate } from 'react-router-dom'
import './custombutton.scss'

function CustomButton({title,course}){
    console.log(course)
    const navigate = useNavigate()
    const handleTitle =() => {
       
        navigate('/course-detail', {
            state: {
              course,
            },
          });
         };
    

    return (<div onClick={handleTitle} className="custom-button">{title}</div>)
}


export default CustomButton;