const coursePage='/courses';
const courseDetail='/course-detail';
const aboutUsPage='/about-us';
const contactUsPage='/contact-us';
const whyZenithPage='/why-zenith';
const privacyPage='/privacy-page';
const termsAndConditions='/terms-and-conditions';
const comingSoon = '/coming-soon'



const homePage='/';




export {coursePage, courseDetail,aboutUsPage,contactUsPage,whyZenithPage,homePage ,privacyPage,termsAndConditions, comingSoon }