import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./RegisterForm.scss";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  getTimeSlot,
  toastIt,
  validateEmail,
} from "../../../../utils/functionalUtils";
import { db } from "../../../../firebase";
import { addDoc, collection } from "firebase/firestore/lite";
import { DemoTimeSlots } from "../../../../constants/listconstant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "30px",
  "@media (max-width: 960px)": {
    width: "45vw", //
  },
  "@media (max-width: 600px)": {
    width: "80vw",
  },
};

function RegisterForm({ isOpen, close, course }) {
  const inputStyle = {
    "& label.Mui-focused": {
      //style for the label
      color: "#8bc34a",
    },
    "& .MuiOutlinedInput-root": {
      //style for the Normal Field
      padding: 0,
      "&.Mui-focused fieldset": {
        //style for the Focused
        borderColor: "#8bc34a",
      },
      "&:hover fieldset": {
        //style for hovering over Field
        borderColor: "#8bc34a",
      },
      "& fieldset": { border: "none" },
    },
  };

  const [isProcessing, setIsprocessing] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phNo: "",
    timeSlot: 0,
  });

  const updateFormData = (e) => {
    const { name, value } = e.target;

    if (name === "phNo") {
      if (!/^\d*$/.test(value)) {
        return;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitForm = async () => {
    setIsprocessing(true);

    if (formData.name === "") {
      toastIt("Name can not be empty !!!", "error");
      setIsprocessing(false);
      return;
    }
    if (formData.email === "") {
      toastIt("Email can not be empty!!", "error");
      setIsprocessing(false);
      return;
    }

    if (formData.phNo === "") {
      toastIt("Phone Number can not be empty!!", "error");
      setIsprocessing(false);
      return;
    }

    if (formData.phNo.length > 0 && formData.phNo.length < 10) {
      toastIt("Please enter a valid Phone Number", "error");
      setIsprocessing(false);
      return;
    }

    if (formData.timeSlot === 0) {
      toastIt("Please select the time slot!!", "error");
      setIsprocessing(false);
      return;
    }

    if (!validateEmail(formData.email)) {
      toastIt("Please enter a valid email", "error");
      setIsprocessing(false);
      return;
    }

    var timeSlotId = formData.timeSlot;
    formData.timeSlot = getTimeSlot(timeSlotId);

    try {
      const docRef = await addDoc(
        collection(db, `RequestedDemoUser_${course}`),
        formData
      );
      toastIt(
        "Thank you for reaching out to us, we'll get back to you!!",
        "success"
      );
      setFormData({ name: "", email: "", phNo: "", timeSlot: 0 });
      setIsprocessing(false);
    } catch (e) {
      console.log(e);
      toastIt(
        "Error occurred! Sorry for the inconvinience, we're working on it",
        "error"
      );
      setIsprocessing(false);
    }
    setIsprocessing(false);
    close();
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" component="h1" className="form-title">
              Request a Demo
            </Typography>
            <p onClick={close} style={{ cursor: "pointer" }}>
              <b>&#x2715;</b>
            </p>
          </Box>
          <Box className="form-fields">
            <form noValidate autoComplete="off">
              <TextField
                className="form-input"
                label="Name"
                name="name"
                variant="outlined"
                fullWidth
                sx={inputStyle}
                value={formData.name}
                onChange={updateFormData}
              />
              <TextField
                className="form-input"
                label="Email"
                name="email"
                variant="outlined"
                fullWidth
                sx={inputStyle}
                value={formData.email}
                onChange={updateFormData}
              />
              <TextField
                className="form-input"
                label="Phone No."
                name="phNo"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 10 }}
                sx={inputStyle}
                value={formData.phNo}
                onChange={updateFormData}
              />
              <FormControl fullWidth sx={inputStyle} className="form-input">
                <InputLabel id="time-slot-label">Suitable Time Slot</InputLabel>
                <Select
                  labelId="time-slot-label"
                  id="time-slot"
                  defaultValue={0}
                  fullWidth
                  label="suitable-time-slot"
                  name="timeSlot"
                  value={formData.timeSlot}
                  onChange={updateFormData}
                  className="custom-select"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Set maximum height for the dropdown menu
                        overflowY: "auto", // Enable vertical scrolling
                      },
                    },
                  }}
                >
                  {DemoTimeSlots.map((slot) => (
                    <MenuItem value={slot.id} key={slot.id}>
                      {slot.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                className="form-submit-button"
                fullWidth
                onClick={submitForm}
              >
                Register
              </Button>
              {isProcessing && <LinearProgress className="custom-progress" />}
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default RegisterForm;
