import React from 'react'
import HeaderContent from '../../components/HeaderContent'
import { bookmark } from "../../assets/gifs/index";
import ContactForm from './components/ContactForm/ContactForm';
import LocationAndHours from './components/LocationAndHours';

function ContactUs() {
  return (
    <>
        <HeaderContent
          title="Get in touch with Zenith"
          description="We’re here to help! Whether you have questions about our courses, need guidance on enrollment, or want more information about our platform, just fill out the form below. Our team is ready to assist you with anything you need"
          image={bookmark}
          isImageFirst={false}
        />
        <ContactForm />
        <LocationAndHours />
    </>
  )
}

export default ContactUs