import "./HomeMainsection.scss";
import { coursePage } from "../../../constants/routes";
import { Fade, Slide } from "react-awesome-reveal";

function HomeMainsection() {
  return (
    <Fade cascade damping={0.25} triggerOnce>
      <div className="home-main-section">
        <Slide cascade damping={0.25} triggerOnce direction="up">
          <h1 className="home-main-section__title title">
            Master the skills of Tomorrow, Today
          </h1>
          <h3 className="home-main-section__description description">
            Join our comprehensive courses in Java, Python, and more.
            <br />
            Learn from expert tutors and gain the skills you need to
            <br />
            succeed in the tech world.
          </h3>
          <a
            href={coursePage}
            className="home-main-section__button description "
          >
            Explore Courses
          </a>
        </Slide>
      </div>
    </Fade>
  );
}

export default HomeMainsection;
