import './CoursesComponent.scss';
import CustomButton from '../../../components/CustomButton/index.js'
import { useNavigate } from 'react-router-dom';

function CoursesComponent({ title, description, courses }) {
    return (
        <div className="courses">
            <h2 className="courses__title title">{title}</h2>
            <p className="courses__description description">
        {description}
            </p>
                <div className="courses__grid">

                    {courses.map(( course,index)=>{  

                        return <CourseCard   key={index} course={course} />
                    })}
                   
                   


                </div>
        </div>
    );
}

function CourseCard({course} ) {
    const navigate = useNavigate()

    const handleCardClick = () => {
        navigate('/course-detail', {
            state: {
              course,
            },
          });
    }
   
    return (
        <div onClick={handleCardClick} className="course-card">
            <div className="course-card__header">
                <img src={course.icon} className="course-card__image" alt="Course" />
            </div>
            <div className="course-card__body">
                <p className="course-card__title">{course.title}</p>
                <p className="course-card__description">
                   {course.description}
                </p>
                
                <CustomButton  title="Learn More" course={course} />
            </div>
        </div>
    );
}

export default CoursesComponent;
