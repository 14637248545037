import "./WhyZenithReasons.scss";

import { whyZenithReasonList } from "../../../constants/listconstant";

import { useInView } from 'react-intersection-observer';

function WhyZenithReasons() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation runs only once when in view
    threshold: 0.2  , // Adjust the threshold as needed
  });

  return (
    <div className="whyZenithReasonsSection" ref={ref}>
      <h1>Why Zenith</h1>

      {whyZenithReasonList.map((reason, index) => {
        return (
          <div className={`whyZenithReasonsSection__card ${inView ? (index % 2 == 0 ? `scalInFromLeft` : `scalInFromRight`) : `hiddenAnimation`}`}>
            <div className="whyZenithReasonsSection__card__left">
              <div className="whyZenithReasonsSection__card__left__icon-container">
                {" "}
                <img
                  src={reason.icon}
                  className="whyZenithReasonsSection__card__left__icon"
                />{" "}
              </div>
            </div>

            <div className="whyZenithReasonsSection__card__right">
              {" "}
              <h2 className="whyZenithReasonsSection__card__title">
                {reason.title}
              </h2>
              <p className="whyZenithReasonsSection__card__description description ">
                {reason.description}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default WhyZenithReasons;
