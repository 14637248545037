import React, { useState, useMemo, useRef, useEffect } from 'react';
import './TestimonialsComponent.scss';
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import artistGuide from '../../assets/images/HomePage/interactive learning.webp';
import {doubleQuote} from '../../assets/images/HomePage/index';
import { femaleAvatar,badrinathBAvatar ,navyaAvatar,prathyushaAvatar,bharathiAvatar,kasturiAvatar } from '../../assets/images/avatars';
function TestimonialsComponent({user}) {
    const testimonials = useMemo(() => [
        {
            name: "Navya A",
            description: "One thing I really like and helped me succeed in my technical interviews is the comprehensive scenarios they teach at end of each topic, this helped me understand each concept with practical implementation. I was very fortunate to choose Zenith for my Learning",
            date: "23 Jun 2024",
            image: navyaAvatar,
        },
        {
            name: "Badrinath B",
            description: "I want to thank Zenith team for your help. I hope to do more trainings in future.!",
            date: "15 Jul 2024",
            image: badrinathBAvatar,
        },
        {
            name: "Kasturi S",
            description: "I had great learning experience and placement assistance also recommended to my friends who were seeking to pursue IT jobs and need support with java/python full stack developer course and interview preparation.",
            date: "30 Aug 2024",
            image: kasturiAvatar,
        },
        {
            name: "Bharathi P",
            description: "Completed my Python course in January  2023 and Professionally, I found the Zenith’s course Intensive and very helpful for carrier. content the support from trainer or faculty is commendable they did an excellent job,  Looking forward to enrolling more courses in future",
            date: "10 Aug 2024",
            image: bharathiAvatar,
        },
        {
            name: "Prathyusha I",
            description: "Coming from an non computer science engineering background, I found the course was well structured with extensive content and I really liked their assignments at end of topic that challenges your understanding on the topic, this keeps you focused. Thank You Team Zenith…!",
            date: "19 sep 2024",
            image: prathyushaAvatar,
        },
       
    ], []);

    const [currentIndex, setCurrentIndex] = useState(0);

    const carouselRef = useRef(null);

    const lastScrollTimeRef = useRef(0);

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    const handleScroll = (event) => {

        const now = new Date().getTime();

        // Ensure at least 500ms have passed since the last scroll
        if (now - lastScrollTimeRef.current < 1000) return;
        lastScrollTimeRef.current = now;

        if (event.deltaX > 0) {
            goToNext();
        } else if (event.deltaX < 0) {
            goToPrevious();
        }
    };

    useEffect(() => {
        const carouselElement = carouselRef.current;
        if (carouselElement) {
            carouselElement.addEventListener('wheel', handleScroll);
        }
        return () => {
            if (carouselElement) {
                carouselElement.removeEventListener('wheel', handleScroll);
            }
        };
    }, [currentIndex]);

    return (
        <div id='artists' className="testimonials__section">
            <p className='testimonials__section__title title'>Testimonials</p>

            <div className='testimonials__carousel' ref={carouselRef}>
                <div className='testimonials__card'>
                        <div className='testimonials__card__left'>
                            <img src={doubleQuote} className='testimonials__card__doublequote' alt="double quotes" />
                            <h2 className='testimonials__card__title'>{testimonials[currentIndex].name}</h2>
                            <p className='testimonials__card__description description'>{testimonials[currentIndex].description}</p>
                            <p className='testimonials__card__time'>{testimonials[currentIndex].date}</p>
                        </div>
                        <div className='testimonials__card__right'>
                            <img src={testimonials[currentIndex].image} className='testimonials__card__image' alt="artist guide" />
                        </div>

                        {/* <h1 className='testimonials-coming-soon'>Coming soon</h1> */}
                </div>
            </div>

            <div className="custom-controls">
                <div className="custom-arrows">
                    <CgChevronLeft onClick={goToPrevious} className="custom-arrow custom-arrow--prev" />
                    <div className="custom-dots">
                        {testimonials.map((_, index) => (
                            <span
                                key={index}
                                className={`custom-dot ${index === currentIndex ? 'active' : ''}`}
                                onClick={() => goToSlide(index)}
                            ></span>
                        ))}
                    </div>
                    <CgChevronRight onClick={goToNext} className="custom-arrow custom-arrow--next" />
                </div>
            </div>
        </div>
    );
}

export default TestimonialsComponent;
