import { toast } from "react-toastify";
import { courseList, DemoTimeSlots } from "../constants/listconstant";

const toastIt = (msg, type) => {
  const toastProperties = {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    theme: "light",
  };
  if (type === "error") toast.error(msg, toastProperties);
  else if (type === "success") toast.success(msg, toastProperties);
};

const validateEmail = (mail) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(mail);
};

const getTimeSlot = (id) => {
  const timeSlot = DemoTimeSlots.find((slot) => slot.id === id);
  return timeSlot ? timeSlot.name : "Invalid Time Slot";
};

// Function to flatten the nested structure into a searchable array
const flattenCourses = (data) => {
  return data.reduce((acc, category) => {
    const courseItems = category.course.map((course, index) => ({
      id: `${category.category}-${index}`, // Unique ID
      name: course.title, // What will be searched
      description: course.description,
      courseSyllabus: course.courseSyllabus,
      image: course.icon
    }));
    return [...acc, ...courseItems];
  }, []);
};

const fetchCourseById = (id) => {
  const [category, index] = id.split("-");
  // Find the category in the courseList
  const categoryObj = courseList.find((item) => item.category === category);

  // If category exists and index is within bounds, return the corresponding course
  if (categoryObj && categoryObj.course[parseInt(index)]) {
    return categoryObj.course[parseInt(index)];
  } else {
    return null; // Return null if category or course doesn't exist
  }
};

export { toastIt, validateEmail, getTimeSlot, flattenCourses, fetchCourseById };
