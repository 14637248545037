import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  Divider,
} from "@mui/material";
import "./NavBar.scss"; // Import the SCSS file
import SectionWrapper from "../SectionWrapper/SectionWrapper";
import { logo_light,zLearnMainLogo } from "../../assets/logo";
import { useNavigate } from "react-router-dom";
import { nav_menu } from "../../assets/icons";

const NavBar = () => {
  const [expanded, setExpanded] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  const navigateNavLink = (page) => {
    navigate(page);
    setExpanded(false); // Close the expanded menu after navigation
  };

  useEffect(() => {
    const menu = menuRef.current;
    if (menu) {
      if (expanded) {
        menu.style.maxHeight = `${menu.scrollHeight}px`;
        menu.style.opacity = 1;
      } else {
        // Smooth collapse transition
        menu.style.maxHeight = `${menu.scrollHeight}px`; // Set to full height before collapsing
        requestAnimationFrame(() => {
          menu.style.maxHeight = "0px"; // Collapse to 0
          menu.style.opacity = 0; // Fade out
        });
      }
    }
  }, [expanded]);

  const currentPath = window.location.pathname;

  return (
    <AppBar
      position="static"
      elevation={0}
      className="navbar"
      sx={{ "& .MuiToolbar-root": { padding: 0 } }}
    >
      <SectionWrapper>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <img
            src={zLearnMainLogo}
            alt="logo"
            className="brand"
            onClick={() => navigateNavLink("/")}
          />
          <Box className="nav-links">
            {/* Main Navigation Links */}
            {["/why-zenith", "/about-us", "/contact-us", "/courses"].map(
              (path) => (
                <Button
                  key={path}
                  className={`nav-link ${currentPath === path ? "active" : ""}`}
                  onClick={() => navigateNavLink(path)}
                >
                  {path === "/why-zenith"
                    ? "Why Zenith"
                    : path.replace("/", "").replace("-", " ")}
                </Button>
              )
            )}
            <Button
              variant="contained"
              className="go-to-app"
              onClick={() => navigateNavLink("/coming-soon")}
            >
              Go to App
            </Button>
          </Box>
          <Box className="menu-icon">
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleExpand}
              sx={{ m: 0, p: 0, justifyContent: "end" }}
            >
              <img
                src={nav_menu}
                alt="menu"
                style={{ width: "30%" }}
                className={`${expanded ? `rotateTo90Deg` : `rotateBackTo0Deg`}`}
              />
            </IconButton>
          </Box>
        </Toolbar>
        {/* Expanded Menu */}
        <Box className="expanded-menu" ref={menuRef}>
          {["/why-zenith", "/about-us", "/contact-us", "/courses"].map(
            (path) => (
              <React.Fragment key={path}>
                <Button
                  className={`nav-link ${currentPath === path ? "active" : ""}`}
                  onClick={() => navigateNavLink(path)}
                >
                  {path === "/why-zenith"
                    ? "Why Zenith"
                    : path.replace("/", "").replace("-", " ")}
                </Button>
                <Divider variant="middle" flexItem />
              </React.Fragment>
            )
          )}
          <Button
            variant="contained"
            className="go-to-app"
            onClick={() => navigateNavLink("/coming-soon")}
          >
            Go to App
          </Button>
        </Box>
      </SectionWrapper>
    </AppBar>
  );
};

export default NavBar;
