import CoursesComponent from "./CoursesComponent/index";
import HeaderContent from "../../components/HeaderContent/index";
import {
  html,
  css,
  java,
  python,
  javascript,
} from "../../assets/images/CoursePage/index";
import SearchField from "../../components/SearchField/index";
import { book } from "../../assets/gifs/index";
import "./coursepage.scss";
import { courseList } from "../../constants/listconstant";
import { fetchCourseById, flattenCourses } from "../../utils/functionalUtils";
import { useNavigate } from "react-router-dom";

function CoursesPage() {
  const navigate = useNavigate();

  const coursesData = flattenCourses(courseList);

  const handleOnSearch = (string, results) => {
    // This is called every time a search is done
    console.log(string, results);
  };

  const handleOnSelect = (item) => {
    const course = fetchCourseById(item.id);
    if (course) {
      navigate("/course-detail", {
        state: {
          course,
        },
      });
    }
    console.log(item);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  return (
    <>
      <div className="course__page">
        <HeaderContent
          title="Courses Offered by Zenith"
          description="Explore our range of computer science courses designed for aspiring engineers. Whether you're just starting or looking to advance your skills, we have something for everyone."
          image={book}
          isImageFirst={true}
        />
        <SearchField
          autoSearchData={coursesData}
          handleOnSearch={handleOnSearch}
          handleOnSelect={handleOnSelect}
          handleOnFocus={handleOnFocus}
          searchKeys={["name", "description", "courseSyllabus"]}
        />

        {courseList.map((courses, index) => {
          return (
            <CoursesComponent
              title={courses.category}
              description={courses.description}
              courses={courses.course}
              key={index}
            />
          );
        })}
      </div>
    </>
  );
}

export default CoursesPage;
