
import {whyZenithImage1,whyZenithImage2,whyZenithImage3,whyZenithImage4,whyZenithImage5} from '../assets/images/whyZenith/index'
import {ai,java,javafullstack,python,dataAnalytics,datascience,} from '../assets/images/popularcourse/index'

import { pradeep,vineeth } from '../assets/images/tutors';

const whyZenithReasonList =[


    {
        'title':"Tailored Learning Experience",
        "description":"At Zenith, we recognize that no two learners are the same. We personalize the learning journey for each student, ensuring they receive the guidance and resources they need to succeed.",
        "icon":whyZenithImage1
    },
    {
        'title':"Future Ready Skills",
        "description":"Our platform is designed to equip engineering students with the skills that are in demand. From hands-on projects to real-world problem-solving, we focus on the competencies that future employers are seeking.",
        "icon":whyZenithImage2
    },
    {
        'title':"Expert-Led Courses",
        "description":"All our courses are created and led by industry professionals and academic experts. This ensures that our students receive the most relevant, up-to-date knowledge in their chosen field.",
        "icon":whyZenithImage3
    },
    {
        'title':"Flexible Learning Paths",
        "description":"Our platform is designed to equip engineering students with the skills that are in demand. From hands-on With Zenith, students can learn at their own pace and on their own schedule. Whether it’s catching up on course material or exploring new topics, our platform gives students the flexibility they need. to real-world problem-solving, we focus on the competencies that future employers are seeking.",
        "icon":whyZenithImage4
    },
    {
        'title':"Graduates Centric",
        "description":"Zenith is uniquely designed for engineering students. Our courses and content are tailored to the specific needs of future engineers, ensuring a deep and comprehensive understanding of core subjects.",
        "icon":whyZenithImage5
    },
    
]


const allCourses = [
    { title: "Artificial Intelligence", image: ai },
    { title: "Python Full Stack Learning", image: python },
    { title: "Data Science", image: datascience },
    { title: "Java Full Stack", image: javafullstack },
    { title: "Java Beginner", image: java },
    { title: "Data Analytics", image: dataAnalytics }
];


const courseList = [
  {
    category: "AI/ML",
    description:
      "AI/ML focuses on empowering machines to learn from data and perform tasks that traditionally require human intelligence. This category explores key concepts in artificial intelligence, such as neural networks, natural language processing, and reinforcement learning. Machine learning models and AI techniques are becoming essential in fields like data analytics, helping organizations make smarter decisions based on data.",
    course: [
      {
        title: "Artificial Intelligence",
        description:
          "This AI course will dive into the fundamentals of artificial intelligence, covering essential topics such as natural language processing (NLP), computer vision, reinforcement learning, and neural networks. You’ll learn how to apply AI techniques using frameworks like TensorFlow and Keras, and gain the knowledge needed to build intelligent systems and applications.",
        icon: ai,
        courseSyllabus: [
          "Introduction to AI",
          "Neural networks and deep learning",
          "Natural Language Processing (NLP)",
          "Computer vision techniques",
          "Reinforcement learning",
          "Using TensorFlow and Keras",
        ],
      },
      {
        title: "Data Analytics",
        description:
          "The Data Analytics course is designed to equip you with the skills needed to interpret and analyze data effectively. You will learn how to clean and process data using tools like Excel, SQL, and Python. You’ll also explore visualization techniques with Tableau and Power BI, ensuring that you can present insights that inform business decisions and strategies. This course will prepare you for a career in analytics.",
        icon: dataAnalytics,
        courseSyllabus: [
          "Data cleaning and processing",
          "Excel, SQL, and Python for analytics",
          "Data visualization with Tableau",
          "Power BI dashboard creation",
          "Presenting data-driven insights",
        ],
      },
      {
        title: "Data Science",
        description:
          "This Data Science course will guide you through the essential skills needed to process and analyze vast amounts of data. You’ll explore key concepts in statistics, machine learning, and data visualization using tools like Python, R, and TensorFlow. By the end, you’ll know how to clean data, build predictive models, and present insights, positioning you for success in the rapidly growing field of data science.",
        icon: datascience,
        courseSyllabus: [
          "Introduction to data science",
          "Data cleaning and preparation",
          "Machine learning algorithms",
          "Data visualization with Python and R",
          "Predictive modeling with TensorFlow",
        ],
      },
    ],
  },
  {
    category: "Web Development",
    description:
      "Web development is a comprehensive field involving the creation of websites and web applications. This category covers both front-end and back-end development, teaching you how to build user interfaces using HTML, CSS, and JavaScript, while handling the server side with frameworks like Spring Boot and databases like MySQL, allowing you to deliver fully functional web apps end-to-end.",
    course: [
      {
        title: "Java Full Stack",
        description:
          "This course offers a comprehensive approach to becoming a Full Stack Java developer. You will start with core Java programming, moving on to front-end development using HTML, CSS, and JavaScript. You'll then dive into back-end development with Java frameworks like Spring Boot, and databases like MySQL. You’ll also learn deployment strategies to deliver fully functional web applications end-to-end.",
        icon: javafullstack,
        courseSyllabus: [
          "Introduction to Java",
          "Front-end development: HTML, CSS, JavaScript",
          "Spring Boot framework",
          "MySQL database integration",
          "Deploying full-stack applications",
        ],
      },
      {
        title: "Python Full Stack",
        description:
          "In this Python Full Stack course, you’ll gain in-depth knowledge of both front-end and back-end development. You’ll start by learning Python programming and its popular web frameworks like Django or Flask. This course will also cover front-end technologies (HTML, CSS, JavaScript), database management with SQL, and version control with Git, preparing you to build scalable, dynamic web applications.",
        icon: python,
        courseSyllabus: [
          "Python programming fundamentals",
          "Django/Flask web frameworks",
          "HTML, CSS, JavaScript for front-end",
          "SQL for database management",
          "Version control with Git",
        ],
      },
    ],
  },
  {
    category: "Programming Languages",
    description:
      "Programming languages are the foundation of software development. This category focuses on teaching core programming principles, syntax, and problem-solving skills. You’ll learn languages like Java, which are widely used in building enterprise-level applications, cross-platform software, and more. Master the core concepts like OOP, collections, exception handling, and advanced features such as networking and multithreading.",
    course: [
      {
        title: "Java",
        description:
          "Java is a versatile and powerful programming language that enables developers to build cross-platform applications. In this course, you’ll master core Java concepts, such as object-oriented programming, collections, and exception handling. Additionally, you’ll learn advanced topics like multithreading, networking, and working with databases to build robust enterprise-level applications.",
        icon: java,
        courseSyllabus: [
          "Introduction to Java programming",
          "Object-oriented programming",
          "Collections and generics",
          "Exception handling and debugging",
          "Multithreading and concurrency",
          "Networking and database connectivity",
        ],
      },
    ],
  },
  
];


const popularCourse = {
    "Java": {
      title: "Java",
      description: "Java is a versatile and powerful programming language that enables developers to build cross-platform applications. In this course, you’ll master core Java concepts, such as object-oriented programming, collections, and exception handling. Additionally, you’ll learn advanced topics like multithreading, networking, and working with databases to build robust enterprise-level applications.",
      icon: "",
      category: "Programming Languages",
    },
    "Java Full Stack": {
      title: "Java Full Stack",
      description: "This course offers a comprehensive approach to becoming a Full Stack Java developer. You will start with core Java programming, moving on to front-end development using HTML, CSS, and JavaScript. You'll then dive into back-end development with Java frameworks like Spring Boot, and databases like MySQL. You’ll also learn deployment strategies to deliver fully functional web applications end-to-end.",
      icon: "",
      category: "Web Development",
    },
    "Data Science": {
      title: "Data Science",
      description: "In this Python Full Stack course, you’ll gain in-depth knowledge of both front-end and back-end development. You’ll start by learning Python programming and its popular web frameworks like Django or Flask. This course will also cover front-end technologies (HTML, CSS, JavaScript), database management with SQL, and version control with Git, preparing you to build scalable, dynamic web applications.",
      icon: "",
      category: "Web Development",
    },
    
    "Data Science": {
      title: "Data Science",
      description: "This Data Science course will guide you through the essential skills needed to process and analyze vast amounts of data. You’ll explore key concepts in statistics, machine learning, and data visualization using tools like Python, R, and TensorFlow. By the end, you’ll know how to clean data, build predictive models, and present insights, positioning you for success in the rapidly growing field of data science.",
      icon: "",
      category: "Data Science",
    },
    "Data Analytics": {
      title: "Data Analytics",
      description: "The Data Analytics course is designed to equip you with the skills needed to interpret and analyze data effectively. You will learn how to clean and process data using tools like Excel, SQL, and Python. You’ll also explore visualization techniques with Tableau and Power BI, ensuring that you can present insights that inform business decisions and strategies. This course will prepare you for a career in analytics.",
      icon: "",
      category: "Data Science",
    },
  };


  const DemoTimeSlots = [
    { id: 0, name: "Select Time Slot" },
    { id: 1, name: "9:00 AM - 10:00 AM" },
    { id: 2, name: "10:00 AM - 11:00 AM" },
    { id: 3, name: "11:00 AM - 12:00 PM" },
    { id: 4, name: "12:00 PM - 1:00 PM" },
    { id: 5, name: "1:00 PM - 2:00 PM" },
    { id: 6, name: "2:00 PM - 3:00 PM" },
    { id: 7, name: "3:00 PM - 4:00 PM" },
    { id: 8, name: "4:00 PM - 5:00 PM" },
    { id: 9, name: "5:00 PM - 6:00 PM" },
    { id: 10, name: "6:00 PM - 7:00 PM" },
  ];


  const tutorData = [
    {
        id:1,
        name: "Pradeep - Solutions Architect" ,
        subject: "Solutions Architect",
        description:"With 18+ years of IT expertise, specializing in developing transaction monitoring and customer screening software for major banks in New York, leveraging Python for Big Data and Data Science solutions",
        image : pradeep,

    },
    {
        id:2,
        name: "Vineeth - Cloud/DevOps Engineer" ,
        subject: "Cloud/DevOps Engineer",
        description:"An experienced DevOps professional with a deep understanding of cloud infrastructure, automation, and CI/CD pipelines. With a strong focus on streamlining operations, they have helped organizations optimize their software delivery processes, ensuring scalability and efficiency across development lifecycles",
        image: vineeth

    },
  
]
  
export  {whyZenithReasonList,allCourses,popularCourse,courseList, DemoTimeSlots,tutorData}