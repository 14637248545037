import React from "react";
import "./LocationAndHours.scss";
import { Box, Typography } from "@mui/material";

import { assets } from "../../../../assets";
import SectionWrapper from "../../../../components/SectionWrapper/SectionWrapper";

const LocationAndHours = () => {
  return (
    <SectionWrapper>
      <Box className="location-hours-container">
        <Box className="location-hours-content">
          <Box className="location-hours-content-text">
            <h3  className="title" style={{margin:"0px"}}>
              Location & Hours
            </h3>
            <p className="description">
              Although we're an online platform, we believe in maintaining a
              personal touch. You can reach out to us anytime via email, or
              during our office hours via phone. We’re based in Hyderabad, and
              our support team is available Monday to Friday, 9 AM - 5 PM IST.
            </p>
          </Box>
          <Box className="info-cards">
            <Box className="info-card">
              <img
                src={assets.svgs.locationSymbol}
                alt="location"
                className="info-icon"
              />
              <p className="description">
                Hyderabad
              </p>
            </Box>
            <Box className="info-card">
              <img
                src={assets.svgs.clockSymbol}
                alt="hours"
                className="info-icon"
              />
           <p className="description">
                Monday-Friday
                <br />9 AM - 5 PM
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </SectionWrapper>
  );
};

export default LocationAndHours;
