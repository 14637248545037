import React, { useState } from "react";
import "./ContactForm.scss";
import {
  TextField,
  Button,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";

import { db } from "../../../../firebase";
import { addDoc, collection } from "firebase/firestore/lite";
import SectionWrapper from "../../../../components/SectionWrapper/SectionWrapper";
import { toastIt, validateEmail } from "../../../../utils/functionalUtils";

import { contactUsGif } from "../../../../assets/gifs";

export default function ContactForm() {
  const inputStyle = {
    "& label.Mui-focused": {
      //style for the label
      color: "#8bc34a",
    },
    "& .MuiOutlinedInput-root": {
      //style for the Normal Field
      padding: 0,
      "&.Mui-focused fieldset": {
        //style for the Focused
        borderColor: "#8bc34a",
      },
      "&:hover fieldset": {
        //style for hovering over Field
        borderColor: "#8bc34a",
      },
      "& fieldset": { border: "none" },
    },
    "& .MuiInputBase-inputMultiline": {
      // Adjust padding conditionally
      padding: 2,
    },
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phNo: "",
    msg: "",
  });

  const [isProcessing, setIsprocessing] = useState(false);

  const updateFormData = (e) => {
    const { name, value } = e.target;

    if (name === "phNo") {
      if (!/^\d*$/.test(value)) {
        return;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitForm = async () => {
    setIsprocessing(true);
    if (formData.name === "") {
      toastIt("Name can not be empty !!!", "error");
      setIsprocessing(false);
      return;
    }
    if (formData.email === "") {
      toastIt("Email can not be empty!!", "error");
      setIsprocessing(false);
      return;
    }

    if (!validateEmail(formData.email)) {
      toastIt("Please enter a valid email", "error");
      setIsprocessing(false);
      return;
    }

    if (formData.phNo.length > 0 && formData.phNo.length < 10) {
      toastIt("Please enter a valid Phone Number", "error");
      setIsprocessing(false);
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "contactedUser"), formData);
      toastIt(
        "Thank you for reaching out to us, we'll get back to you!!",
        "success"
      );
      setFormData({ name: "", email: "", phNo: "", msg: "" });
      setIsprocessing(false);
    } catch (e) {
      console.log(e);
      toastIt(
        "Error occurred! Sorry for the inconvinience, we're working on it",
        "error"
      );
      setIsprocessing(false);
    }
  };

  return (
    <SectionWrapper>
      <Box className="contact-form-container">
        <Box className="contact-form-content">
          <Box className="form-heading">
            <h3  className="title" style={{margin:"0px"}}>
              Contact Zenith
            </h3>
            <p className="contact-description description">
              Need guidance on enrollment, or want<br/>more information about our
              platform.<br />
              Fill this form and we will answer your<br/>queries.
            </p>

            <img className="contactUsGif"  src={contactUsGif}></img>
          </Box>
          <Box className="form-fields">
            <form noValidate autoComplete="off">
              <TextField
                className="form-input"
                label="Name"
                name="name"
                variant="outlined"
                fullWidth
                sx={inputStyle}
                value={formData.name}
                onChange={updateFormData}
              />
              <TextField
                className="form-input"
                label="Email"
                name="email"
                variant="outlined"
                fullWidth
                sx={inputStyle}
                value={formData.email}
                onChange={updateFormData}
              />
              <TextField
                className="form-input"
                label="Phone No."
                name="phNo"
                variant="outlined"
                inputProps={{ maxLength: 10 }}
                fullWidth
                sx={inputStyle}
                value={formData.phNo}
                onChange={updateFormData}
              />
              <TextField
                className="form-input"
                label="Message"
                name="msg"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                sx={inputStyle}
                value={formData.msg}
                onChange={updateFormData}
              />
              <Button
                variant="contained"
                className="form-submit-button"
                fullWidth
                onClick={submitForm}
                disabled={isProcessing}
              >
                Submit
              </Button>
              {isProcessing && <LinearProgress className="custom-progress" />}
            </form>
          </Box>
        </Box>
      </Box>
    </SectionWrapper>
  );
}
