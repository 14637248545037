import NavBar from "../../components/Navbar/index";
import Footer from "../../components/Footer/Footer";
 
 function HeaderWrapper({child}){

    return(
        <>
        <NavBar />
        {child}
        <Footer/>
        </>
    )


 }


 export default HeaderWrapper