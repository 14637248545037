import React from "react";
import {
  aboutUs1,
  aboutUs2,
  aboutUs3
} from "../../assets/images/AboutUs/index";
import { tutorData } from "../../constants/listconstant";
import "./About.scss";
import { Slide } from "react-awesome-reveal";

const AboutUs = () => {
  return (
    <div>
      <section className="about-us-section">
        <h1 className="title"> About Zenith</h1>
        <p className="description">
          At Zenith, we're committed to empowering students
          <br />
          with the skills they need to excel in the ever-evolving
          <br />
          field of computer science. Our mission is to provide
          <br /> high-quality education through interactive and
          <br /> accessible online courses.
        </p>
      </section>

      <section className="highlight-section">
        <div className="content description">
          <Slide direction="left" triggerOnce>
            <p>
              <q className="description">
                We combine the best aspects of traditional classroom learning
                with the convenience and flexibility of online education. Our
                courses are led by industry experts who bring real-world
                experience into the virtual classroom. With our interactive Zoom
                sessions, you'll get the chance to engage directly with tutors
                and peers, fostering a collaborative learning environment.
              </q>
            </p>
          </Slide>
          <div className="images">
            <Slide direction="right" cascade damping={0.075} triggerOnce>
              <img src={aboutUs1} alt="Image 1" />
              <img src={aboutUs2} alt="Image 2" />
              <img src={aboutUs3} alt="Image 3" />
            </Slide>
          </div>
        </div>
      </section>
      <section className="youtube">
      <iframe
  src="https://www.youtube.com/embed/1Thl3SoHstU"
  title="YouTube video player"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  referrerpolicy="strict-origin-when-cross-origin"
  allowfullscreen
></iframe>


      </section>

      <section className="tutors-section">
        <h2 className="title">Meet our tutors</h2>
        <div className="cards">
          {tutorData.map((tutor, index) => {
            return (
              <div key={index} className="card">
                <div className="text">
                  <h3>{tutor.name}</h3>
                  <p className="description">{tutor.description}</p>
                </div>
                <img
                  src={tutor.image}
                  alt="Michael R."
                  className="tutor-image"
                />
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
