

import './WhyZenithMisson.scss'

function WhyZenithMisson({title,description}){

    return (<div  className='whyZenithMission'>
        <h1  className='whyZenithMission__title'>{title}</h1>
        <div  className='whyZenithMission__description'>{description}</div>
    
    </div>)
}


export default WhyZenithMisson