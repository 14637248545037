import React from "react";
import "./OfferBanner.scss"

import offer from "../../assets/images/CoursePage/offer.png";
import smily from "../../assets/images/CoursePage/smily.png";

function OfferBanner({offerTitle, descLine1, descLine2}) {
  return (
    <div className="special-offer">
      <div className="special-offer__content">
        <h2 className="title">
          {offerTitle}{" "}
          <img
            style={{ width: "20px", height: "20px", marginLeft: "10px" }}
            src={smily}
            alt="smily"
          />
        </h2>
        <p>
          {descLine1}
          <br />
          {descLine2}
        </p>
      </div>
      <div className="special-offer__image">
        <img src={offer} alt="Special Offer Design" />
      </div>
    </div>
  );
}

export default OfferBanner;
